import React from "react"
import styled, { css } from "styled-components/"
import { media } from "../styles/media"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from "gatsby"
import { FaRegLightbulb } from "react-icons/fa"
import { GiBread } from "react-icons/gi"
import { IconContext } from "react-icons"

// components
import InquiryForm from "../components/InquiryForm"
import { Layout } from "../components/Layout"

import { useLocationData } from "../hooks/locationHook"

const StyledToastContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast-body {
    color: #fff;
  }
`

const StyledInquiryWrap = styled.div`
  width: 80%;
  max-width: 1000px;
  margin: 10px auto;
  ${() =>
    media.tablet(css`
      width: 90%;
    `)}
`

const notify = {
  success: message => {
    toast.success(message)
  },
  error: message => {
    toast.error(message)
  },
}

const StyledInquiryTop = styled.div`
  margin: 50px 10% 30px;
  ul {
    list-style-type: square;
    li {
      margin-bottom: 0.62em;
      font-size: 0.9em;
    }
  }
  ${() =>
    media.sp(css`
      margin: 20px 10%;
    `)}
`
const LinksBeforeForm = styled.div`
  padding: 30px 25% 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  a {
    padding: 0.62em 3.62em;
    border-radius: 10px;
    text-align: center;
    color: white;
    line-height: 1em;
    font-size: 0.9em;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    transition: 0.3s;
    svg {
      margin-bottom: 0.32em;
    }
    :nth-child(1) {
      background: ${({ theme }) => theme.colors.primary.red};
      &:hover {
        background: rgba(0, 0, 0, 0.8);
      }
    }
    :nth-child(2) {
      background: ${({ theme }) => theme.colors.primary.green};
      &:hover {
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
  ${() =>
    media.tablet(css`
      padding: 20px 15%;
    `)}
  ${() =>
    media.sp(css`
      a {
        margin-bottom: 1.62em;
        flex-flow: row nowrap;
        svg {
          margin-right: 0.62em;
        }
      }
    `)}
`

const Inquiry = ({ location }) => {
  useLocationData(location.pathname)
  return (
    <Layout>
      <StyledInquiryTop>
        <ul>
          <li>
            こちらは会社全般に関するお問合せフォームです。フランソアの商品に関するお問合せについては、お手数ですが下記「商品に関するお問合せ」からお問合せください。
          </li>
          <li>
            お問い合わせの受付確認について
            <br />
            以下のフォーム送信後に自動返信メールをお送りいたします。
            <br />
            ※１日経過しても届かない場合は、お手数ですがその旨をお問い合わせください。
            <br />
            （フリーダイヤル：0120-894-180）
          </li>
          <li>
            お問い合わせ内容へのご返信について
            <br />
            通常７日以内には返信いたします。
            <br />
            ※メールアドレスに誤りがある場合、またシステム障害の際には、ご返答できない場合もございます。
          </li>
          <li>
            返信が届かない場合について
            <br />
            ７日経過しても返信がない場合は、お手数ですが再度フォームにてお問い合わせください。
          </li>
          <li>
            返信内容の取り扱いについてのお願い
            <br />
            弊社からの返答はお客様個人宛てにお送りするものです。
            <br />
            返答の一部または全体の転載・二次利用はお断りいたします。
          </li>
          <li>
            セールス、勧誘等について
            <br />
            こちらのお問い合わせフォームからのセールス、勧誘等は固くお断りいたします。
            <br />
            （送信いただいても対応いたしかねます）
          </li>
        </ul>
      </StyledInquiryTop>

      <LinksBeforeForm>
        <Link to="/inquiry/faq-page/">
          <IconContext.Provider value={{ color: "white", size: "1.62em" }}>
            <FaRegLightbulb />
          </IconContext.Provider>
          お問合せの前に
          <br />
          〜よくあるご質問
        </Link>
        <a
          href="https://www.francois.co.jp/inquiry/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconContext.Provider value={{ color: "white", size: "1.62em" }}>
            <GiBread />
          </IconContext.Provider>
          商品に関する
          <br />
          お問合せはこちら
        </a>
      </LinksBeforeForm>

      <StyledToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <StyledInquiryWrap>
        <InquiryForm notify={notify} />
      </StyledInquiryWrap>
    </Layout>
  )
}
export default Inquiry
